import { InjectionToken } from '@angular/core';

// referrals-rc-0e7c6bcbf9 will be replaced during build step,
// it is used by sentry to match sourcemaps

export const AppRevisionToken = new InjectionToken('APP_REVISION');
export const AppRevisionProvider = {
  provide: AppRevisionToken,
  useValue: `referrals-rc-0e7c6bcbf9`,
};
